@import '../../../common.scss';

.search-form {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.mobile {
    position: relative;
    border-radius: 1.75rem;
    height: 3.5rem;
    background: #eee;

    input {
      width: 100%;
      height: 100%;
      background: transparent;
      font-size: 1.25rem;
      padding: 0rem 0 0.1rem 4.5rem;
      border: none;
    }

    img {
      position: absolute;
      width: 2rem;
      margin-left: 1.5rem;
    }
  }

   &:not(.mobile) {
    height: 3.85rem;

    input {
      flex: 1 1 auto;
      width: 36rem;
      height: 100%;
      font-size: 1.8rem;
      padding: 0 2.5rem;
      margin: 0;
      background: #eee;
      border: none;
      border-radius: 2.125rem;

      &:focus {
        outline: none;
      }

      &::placeholder { color: #b0b0c0; }
    }

    .search-button {
      flex: 0 0 auto;
      width: 5rem;
      height: 100%;
      padding: 0 4px;
      border-radius: 2.125rem;
      margin-left: 2rem;
      border: none;
      background: #00a090;
      img {
        height: 56%;
      }
    }
  }
}
