@import '../../../common.scss';

.post-item {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  line-height: 1.25em;
  padding: #{2 * $col-padding}px 0;
  border-bottom: solid 1px #cecece;
  min-height: 100px;

  .image-link {
    flex: 0 0 280px;
    width: 280px;
    height: 168px;

    img {
      border-radius: 20px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .details {
    position: relative;
    flex: 1 1 auto;
    margin-left: #{2 * $col-padding}px;


    .title {
      font-size: 16px;
      display: block;
      font-weight: 500;
      padding-bottom: 8px;
    }

    .created-at-and-category {
      color: #a7a9ac;
      padding-bottom: 12px;
      flex-wrap: wrap;
      display: flex;
      align-items: baseline;
      white-space: normal;
      text-overflow: ellipsis;
      overflow: hidden;

      .created-at { padding-right: 8px; }
    }

    .lead {
      color: #231f20;
      padding-bottom: 48px;
      font-size: 14px;
      font-weight: 400;
    }

    .post-link {
      color: #000;

      &.more {
        position: absolute;
        bottom: 0;

        .button { color: #fff; }
      }
    }

    .created-at-and-category .created-at, .more.post-link {
      display: none;
    }

  }

  &.no-image {
    background: #f0eeed;
    border-radius: 20px;
    border: 0;
    margin: 16px 0;

    .image-link { display: none; }
    .post-link.more .button { color: #000; }
  }
}

.article-container {
  background-repeat: no-repeat;
  background-size: 100% auto;
}