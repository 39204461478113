@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.newsletter-container {
  background-color: #b7b7dd;
  font-family: 'Montserrat', serif;
  padding: 0 20px !important;
  
  .privacy-link {
    display: inline;
    padding: 0;
    margin: 0;
  }

  .message {
    font-size: 11em;
    padding: 1rem 1.5rem;
    font-weight: 500;
    &.error {
      background: #f00;
      color: white;
    }
    &.success {
      background: #0a0;
      color: #fff;
    }
  }

  .newsletter-title {
    font-size: 13em;
    font-weight: 700;
    padding: 16px 0;
  }

  .newsletter-description {
    font-family: 'Roboto Slab', serif;
    font-size: 11em;
    font-weight: 500;
    padding-top: 16px;
  }

  .newsletter-button {
    border: 2px solid #b7b7dd;
    background-color: #1d1d1b;
    color: white;
    border-radius: 0;
    font-weight: 700;
    font-size: 11em;
    padding: 0.6rem 6rem 0.6rem 1.25rem;
  }

  .newsletter-input {
    border: 2px solid mediumpurple;
    color: #000;
    background-color: #d3d3ec;
    font-size: 11em;
    border-radius: 0;
    font-weight: 700;
    padding: 0.6rem 1.25rem 0.6rem 1.25rem;

    &::placeholder {
      color: #000;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .newsletter-container {
    .newsletter-title {
      font-size: 18em;
      font-weight: 700;
      padding: 8px 0 0 0;
    }

    .newsletter-description {
      font-size: 18em;
      padding-bottom: 16px;
      color: #000;
    }

    .newsletter-button {
      font-size: 18em;
    }

    .newsletter-input {
      font-size: 18em;
    }
    .message {
      font-size: 18em;
    }  
  }
}

@media (min-width: $breakpoint-desktop) {
  .newsletter-container {
    padding: 3rem 5rem !important;

    .newsletter-title {
      font-size: 24em;
      font-weight: 700;
    }

    .newsletter-description {
      font-size: 21em;
      padding-bottom: 16px;
    }

    .newsletter-button {
      font-size: 21em;
      padding: 10px 40px 10px 20px;
    }

    .newsletter-input {
      font-size: 21em;
      padding: 10px 20px;
    }

    .message {
      font-size: 21em;
    }  
  }
}
