@import "@csalad-2021/common/common.scss";
@import "../../../common.scss";

.vedjegy-kereso {
  padding-top: 30px;
  padding-bottom: 30px;
  .kereso-title {
    font-size: 30px;
  }
}
