@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.button {
  display: inline-block;
  border: none;
  width: 36px;
  height: 36px;
  background: transparent;
  box-sizing: content-box;
  overflow: hidden;
  cursor: pointer;
  transition: opacity 0.1s;
  margin: 0.5rem 0.75rem;
  padding: 1rem 1.25rem;

  &:focus {
    outline: none;
    opacity: 0.4;
  }

  &.dark {
    color: white;
    background: black;

    &.outlined {
      border: solid 1px white
    }
  }
  
  &.light {
    color: black;
    background: white;

    &.outlined {
      border: solid 1px black
    }
  }

  &:disabled {
    background: #eee;
  }

  &.text {
    font-size: 0.75rem;
    font-weight: 700;
    width: auto;
    height: auto;
    white-space: nowrap;
  }
}

.button:hover:not(:disabled) {
  opacity: 0.7;
}


@media (min-width: $breakpoint-tablet) {
  .button {
    margin: 0.5rem 0.75rem;
    padding: 1rem 1.75rem;

    &.text {
      font-size: 1.15rem;
    }
  }
}