@import '../../../common.scss';

.subscription {
  font-size: 16px;

  .subscription-post.post {
    border-color: #e0e0e0 !important;
    margin-bottom: 16px;

    .lead { font-weight: 500; }
  }

  input {
    width: 75%;
    font-size: 18px;
    height: 32px;
    padding: 4px 20px;
    background: #f1f1f1;
    vertical-align: middle;
    border: none;
    border-radius: 20px;

    &:focus {
      outline: none;
      background: #f1f1f1;
    }

    &::placeholder { color: #888; }
  }

  .error {
    color: #c22;
    font-weight: 700;
    margin: 8px 0 16px;
    font-size: 18px;
  }

  .privacy-check-item {
    font-weight: 500;
    margin: 16px 0 20px 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    .Checkbox {
      display: block;
      cursor: pointer;
      position: relative;
      top: 0;
      width: 24px;
      height: 24px;
      outline: none;
      flex: 0;
      margin: 4px;
    }

    label {
      padding-left: 8px;
      display: block;
      white-space: normal;
      flex: 1 0 auto;
      width: 100%;
    }

    .privacy-checkbox {
      * {
        transition-delay: 0ms !important;
        transition-duration: 0ms !important;
      }

      &.checked .square {
        fill: #37b34a;
        stroke: #37b34a;
        fill-opacity: 1;
      }

      &.unchecked .square {
        fill: #e7e7e7;
        stroke: #e7e7e7;
        fill-opacity: 1;
      }
    }
  }
}

