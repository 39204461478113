@import "@csalad-2021/common/common.scss";

.full-width-cta {
  .ctaWrapper {
    width: 100%;
    .ctaElement {
      width: 100%;
      display: inline-block;
      text-align: center;
    }
  }
}

.ctaWrapper {
  position: absolute;
  bottom: calc(-3vw - 4px);
  right: 32px;
  z-index: 1;
  a {
    text-decoration: none !important;
  }
}

.ctaElement {
  font-size: 1.2vw;
  padding: 0 2vw;
  display: inline-block;
  height: 3vw;
  line-height: 3vw;
  transform: scale(1.5);
  transform-origin: top right;
}

.ctaElement.egyensulyban {
  position: relative;
  font-size: 1.8vw;
  padding: 0 3vw;
  display: inline-block;
  height: 4vw;
  line-height: 4vw;
  margin-bottom: -1vw;
}

.ctaElement.egyensulyban:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -8px;
  height: 100%;
  width: 20px;
  content: "";
  background-size: contain;
  background-image: url("/resources/images/egyensulyban_gomb_left.png");
  background-repeat: no-repeat;
}

@media (max-width: $breakpoint-tablet) {
  .ctaWrapper {
    bottom: calc(-8vw - 4px);
    right: unset;
    .ctaElement {
      font-size: 5.6vw;
      height: 8vw;
      line-height: 8vw;
      transform: none;
    }
  }
}
