@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.multi-post-section {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 2;

  .row {
    width: 100%;
    box-sizing: content-box;
  }
}
/*
@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .multi-post-section {
  }
}

@media (min-width: $breakpoint-desktop) {
  .multi-post-section {
  }
}
*/