@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.survey-question {
  margin: 8px 0 32px 0;
  .question-title h3 { margin: 16px 0; }

  .subtitle p {
    padding: 0;
    margin: 0 0 0 20px;
  }

  .separator {
    width: 300px;
    border-top: solid 4px $primary-color;
    margin: 16px 20px;
  }

  .answer { margin: 8px 20px; }
}
