@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.search-page {
  display: block;

  .search-title {
    padding: 2.25rem 0;
    text-align: center;
    font-family: 'Bodoni Moda', serif;
    font-size: 26em;
    font-weight: 700;
    color: #000;
    text-transform: uppercase;
  }

  .posts {
    padding: 0 1.5rem;

    .search-empty-list {
      color: #000;
      display: block;
    }

  }
}


@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .search-page {
    
    .search-title {
      padding: 3.5rem 0;
      font-size: 65em;
    }
    .post-tile {
      padding-bottom: 2.55rem;
      margin: 0 0.75rem;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .search-page {

    .search-title {
      padding: 3.5rem 0;
      font-size: 65em;
    }
    .post-tile {
      padding-bottom: 2.55rem;
      margin: 0 0.75rem;
    }
  }
}
