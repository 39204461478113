@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.post-page {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .page-inner {
    width: 100%;
    // margin: #{$col-padding}px;
    overflow: hidden;
  }

  .post {
    position: relative;
    z-index: 0;
    padding: 11.75rem 0 2rem 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    

    &.no-image {
      background-size: 0 0;
      padding: 0;

      .main-article-title {
        margin-top: 0;
      }
    }

    .post-image {
      position: absolute;
      top: 0;
      z-index: -1;

      .post-image-inner { position: relative; }
      img { width: 100% }
      
      .post-image-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background: radial-gradient(transparent, #0000007a);
      }
    }

    .post-meta {
      font-family: "Montserrat", serif;
      font-size: 14em;
      font-weight: 700;
      color: #333;

      .separator {
        display: block;
        width: 3.5rem;
        padding: 0 3.5rem 0 0;
        border-top: solid 0.2rem $header-border-color;
        padding-top: 1em;
      }

      .tags {
        overflow: hidden;
        overflow-wrap: break-word; 
        margin: 0 0 1.25rem 0;
  
        .item {
          &:hover {
            color: #000;
          }

          &:before {
            content: '/';
            padding-right: 0.5em;
          }
            &:first-child::before {
            content: '';
            padding-right: 0;
          }

          font-weight: 400;
          padding: 0 0.5em 0 0;
          color: $link-color;
          text-decoration: none;
        }
      }
   
      .attribs {      
        margin: 0 0 1rem 0;

        .label { margin-right: 0.5rem; }
      }
    }

    .clear {
      clear: both;
    }
  }

  .gallery-image {
    position: relative;
    cursor: pointer !important;

    &:hover {
      opacity: 0.9;
    }

    &::after {
      position: absolute;
      bottom: 16px;
      left: 16px;
      font-family: 'Material Icons';
      font-size: 22px;
      color: white;
      content: 'perm_media';
    }
  }

  .gallery {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.95);
    z-index: 100;
    display: flex;
    flex-direction: column;

    .gallery-head {
      flex: 0;
      padding: 8px 60px 8px 16px;

      .title {
        color: white;
        font-size: 20px
      }
    ;

      .lead {
        color: white;
        font-size: 16px
      }
    ;

      .button {
        position: absolute;
        right: 16px;
        top: 8px;
        color: black;
        opacity: 0.8;
        background: white;
        border-radius: 20px;

        &:hover {
          opacity: 1;
        }
      }
    }

    .carousel-root {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 1px;

      .thumbs {
        display: flex;

        .thumb {
          align-self: center;
          border: solid 2px #444;

          &.selected {
            border: solid 2px #fff;
          }
        }
      }

      .carousel {
        width: 100%;
        flex: 0;

        .control-dots {
          top: 0;
        }

        .control-arrow {
          padding: 16px;
          width: 50%;

          &:hover, &:focus, &:active {
            background: transparent;
          }

          &.control-prev {
            text-align: left;
          }

          &.control-next {
            text-align: right;
          }
        }

        &.carousel-slider {
          flex: 1;
        }

        .slider-wrapper {
          height: 100%;
        }

        .slider {
          height: 100%;
        }

        .slide {
          display: flex;
          justify-content: center;
        }
      }

      .carousel-status {
        display: none;
      }

      .slide {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .gallery-item {
          position: relative;
          height: 100%;

          .gallery-item-title {
            color: white;
            text-align: center;
            bottom: 0;
            left: 0;
            width: 100%;
            position: absolute;
            width: 100%;
            background: rgba(0, 0, 0, 0.55);
            padding: 16px 24px;

          }

          img {
            object-fit: contain;
            height: 100%;
          }
        }
      }
    }
  }

  article {
    .main-article-title {
      margin-bottom: 0;
      padding: 2.25rem 1.5rem 0 1.5rem;
      width: 22.5rem;
      background-color: $app-background-color;
      border-radius: 0 1rem 0 0;
      line-height: 1.25em;
      font-family: "Bodoni Moda", serif;
      font-size: 26em;
      font-weight: 400;
    }

    .main-article-lead {
      font-family: "Roboto Slab", serif;
      font-size: 14em;
      line-height: 1.3em;
      font-weight: 800;
      padding: 1.75rem 1.5rem 0 1.5rem;
      margin: 0;
      background-color: $app-background-color;
      color: #666;

      a { 
        color: $link-color;
        text-decoration: underline;
      }
    }

    .main-article-info {
      font-size: 11em;
      font-family: "Montserrat", serif;
      margin: 0;
      padding: 1.75rem 1.5rem 0 1.5rem;
      background-color: $app-background-color;
      text-transform: uppercase;

      & > *:before {
        color: #969696;
        content: " / ";
      }

      .created-by {
        white-space: nowrap;

        &:before {
          display: none;
        }
        .label {
          color: #969696;
          padding-right: 0.4em;
        }
        .value {
          font-weight: 700;
          color: #000;
        }
      }

      .created-at {
        white-space: nowrap;
        color: #969696;
      }

      .category {
        white-space: nowrap;
        font-weight: 700;
        color: #828181;
      }

    }

    .post-content {
      padding: 1.75rem 1.5rem 0 1.5rem;


      iframe{
        max-width: 100% !important;
        // height: 70vh !important;
      }
    }

    .post-meta {
      padding: 2.5rem 1.5rem 0 1.5rem;
    }
  }
}

.rrfDesktop{
  display: none;
}
.rrfMobil{
  display: block;
}

.sz2020felso{
  top: 42px;
}

@media (min-width: $breakpoint-tablet) {

  .rrfDesktop{
    display: block;
  }
  .rrfMobil{
    display: none;
  }

  .sz2020felso{
    top: 58px;
  }

  .post-page {
    padding: 5rem;

    .page-inner .row .col-12 {
      padding-right: 3.75rem;
    }

    .post {
      padding: 16.25rem 0 0.75rem 0;

      .post-meta {
        font-size: 24em;
      }
    }

    article {
      .main-article-title {
        font-size: 48em;
        width: 66%;
        margin-top: 18.75rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-top: 3.75rem;
        padding-bottom: 0.75rem;
      }

      .main-article-lead {
        font-size: 21em;
      }

      .main-article-info {
        font-size: 21em;
      }
    }
  }
}
