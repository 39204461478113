@use "sass:math";

$col-padding: 12;

body {
  .btn-primary.disabled, .btn-primary:disabled {
    color: #888;
    background-color: #ddd;
    border-color: #aaa;
  }
}