@import "~@fontsource/material-icons/index.css";
@import "~@fontsource/montserrat/400";
@import "~@fontsource/montserrat/500";
@import "~@fontsource/montserrat/600";
@import "~@fontsource/montserrat/700";
@import "~@fontsource/montserrat/800";
@import "~@fontsource/montserrat/900";
@import "~@fontsource/roboto-slab/400";
@import "~@fontsource/roboto-slab/500";
@import "~@fontsource/roboto-slab/600";
@import "~@fontsource/roboto-slab/700";
@import "~@fontsource/roboto-slab/800";
@import "~@fontsource/roboto-slab/900";
@import "~@fontsource/bodoni-moda/400";
@import "~@fontsource/bodoni-moda/500";
@import "~@fontsource/bodoni-moda/600";
@import "~@fontsource/bodoni-moda/700";
@import "~@fontsource/bodoni-moda/800";
@import "~@fontsource/bodoni-moda/900";

@font-face {
  font-family: "Bodoni Moda";
  src: local("Times New Roman");
  font-weight: 700;
  unicode-range: U+002B;
}

@font-face {
  font-family: "Bodoni Moda";
  src: local("Times New Roman");
  font-weight: 400;
  unicode-range: U+002B;
}
