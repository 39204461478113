@import "../../../common.scss";
@import "@csalad-2021/common/common.scss";

h3.onkent-jottem-vote {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 20px;
}

table.onkent-jottem-vote {
  min-width: 70%;
  font-size: 13px;
  text-transform: uppercase;
  color: #aaa;
  font-weight: bold;
  padding-top: 20px;
  tr {
    border-top: 1px solid #aaa;
    td {
      padding: 10px 10px 10px 0;
    }
  }
}
table.onkent-jottem-vote.voted {
  tr {
    border-top: none;
    td:last-of-type {
      text-align: right;
      padding: 10px 0 10px 10px;
    }
  }
}

table.onkent-jottem-vote-privacy {
  min-width: 70%;
  font-size: 13px;
  color: #000;
  font-weight: bold;
  padding-top: 20px;
  margin: 20px 0;
  text-align: left;
  tr {
    td {
      padding: 10px 10px 10px 0;
    }
  }
}

.voteButton {
  font-weight: "bold";
  color: #fff;
  cursor: pointer;
  background-color: #469d8f;
  font-size: 15px;
  padding: 0 20px;
  display: inline-block;
  height: 30px;
  line-height: 30px;
}
.pmob {
  padding: 1.75rem 1.5rem 0 1.5rem;
}

@media (max-width: $breakpoint-desktop) {
  .percentBar {
    zoom: 0.6;
  }
}

@media (max-width: $breakpoint-tablet) {
  .voteButton {
    font-size: 15px;
  }
  table.onkent-jottem-vote {
    font-size: 12px;
  }
  .percentBar {
    zoom: 0.4;
  }
}
