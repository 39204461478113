.category-breadcrumb {
  display: block;
  margin: 20px 0;

  .bread {
    color: #969696;
    font-size: 14px;
    font-weight: 500;

    &::after {
      padding: 0 4px;
      content: '>';
    }

    &:last-child {
      color: #231f20;

      &::after {
        content: '';
      }
    }
  }
}

.a11y .category-breadcrumb {
  .bread {
    color: #aa0;

    &:last-child {
      color: #ff0;
    }
  }
}
