@import '@csalad-2021/common/common.scss';
@import '../common.scss';

.app {
  position: relative;
  background-color: $app-background-color;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: $base-font-size-mobile;
  margin-top: 130px;
  max-width: 1920px;
}
