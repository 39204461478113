@import "@csalad-2021/common/common.scss";
@import "../../../common.scss";

$transition-time: 0.3s;

$base-indent: 0;
$level-indent: 0;

$menu-background: transparent;

$item-color: black; // white
$arrow-color: black; // white
$item-background: transparent; // #38B349;
$opened-item-background: transparent; // #019444;

.category-menu {
  position: relative;

  .menu-big-text {
    text-transform: uppercase;
    font-family: "Montserrat", serif;
    font-weight: bold;

    .dropdown-toggle {
      color: #000000 !important;
      white-space: pre-wrap;
    }

    .dropdown-menu {
      border: none;
    }
  }

  .mobile.menu-list .menu-big-text {
    font-size: 16em;
    letter-spacing: 1px;
    line-height: 1.2;
    color: #000000 !important;
  }

  .menu-small-text {
    text-transform: none;
    font-family: "Montserrat", serif;
    font-weight: bold;
    text-decoration: none;
    color: #828181;
  }

  .menu-small-text:active,
  .menu-small-text:hover,
  .menu-small-text:focus {
    color: inherit !important; // parent <li> will set the category color
  }

  .mobile.menu-list {
    padding-left: 1rem;
    padding-bottom: 3rem;
  }

  .mobile.menu-list .menu-small-text {
    font-size: 1em;
    line-height: 1.8;
    letter-spacing: 0;
  }
}

@media (min-width: $breakpoint-tablet) {
  .category-menu {
    &.navbar-nav.navbar-nav {
      flex-direction: row;

      .nav-link {
        padding: 0;
        margin: 0;
        &.menu-small-text {
          color: #000000;
        }
      }

      > .nav-item {
        color: black;
        padding: 0 0 0.2083vw 0;
        margin: 0 0.9375vw;
        border-bottom: 0.3125vw solid #ffffff;
        font-size: 20em;

        &.nav-item:hover,
        &.nav-item:focus,
        &.nav-item.active,
        &.nav-link:hover,
        &.nav-link:focus,
        &.nav-link.active {
          border-bottom: 0.3125vw solid $header-border-color;
        }
        &.no-hover {
          border: none !important;
        }
      }

      .hamburger {
        position: initial;
        font-size: 20em;
        line-height: 0;

        .hamburger-icon {
          fill: $header-border-color;
          width: 1.4583vw;
          height: 1.4583vw;
          margin: 0 0 0px 0;
        }

        > .dropdown-toggle {
          color: #ec658c !important;

          &::after {
            display: none;
          }
        }

        > .dropdown-menu {
          flex-direction: column;
          line-height: 2.25vw;
          position: absolute !important;
          border-radius: 0;
          border-left: 2px solid $header-border-color;
          border-bottom: 2px solid $header-border-color;
          padding: 24px;
          left: auto;
          top: -3px;
          right: -3px;
          font-size: 1em;
          width: 32vw;

          &.show {
            display: flex;
          }

          .desktop-dropdown {
            > .dropdown-menu {
              line-height: 1.3em;
              margin-left: 1rem;
              margin-top: 0;
              padding-top: 0;
            }

            .dropdown-toggle {
              display: flex;
              flex-direction: row;
              align-items: center;

              .toggle-button {
                padding-left: 1rem;
                margin-bottom: 0.3rem;
                svg {
                  width: 1.25em;
                  height: 1.25em;
                  transform: rotate(0deg);
                  fill: #d46159;
                  transition: all 0.2s;
                }
                &.opened svg {
                  transform: rotate(90deg);
                }
              }
              &::after {
                border: none;
              }
            }
          }
        }
        .close-button {
          height: 3.25vw;
          stroke: black;
          background-color: transparent;
          display: inline-block;
          margin: 0 0 0.2vw 0;
          padding: 0;
          text-align: right;
          align-self: end;
          width: 2.4vw;
          height: 3vw;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }

      .search {
        &.nav-item {
          margin: 0px 0px 0px 0.9375vw;
          padding: 0px;
        }
        img {
          width: 1.875vw;
          margin-bottom: calc(6px + 0.5vw);
        }
      }
      .en-btn {
        &.nav-item {
          margin: 0px 32px 0px 0.9375vw;
          padding: 0px;
        }
        img {
          width: 1.875vw;
          margin-bottom: 0.2vw;
        }
      }
    }

    .menu-big-text {
      display: block;
      color: black;
    }

    .menu-big-text.family {
      .dropdown-toggle::after {
        content: none !important;
      }
      .dropdown-menu {
        position: absolute !important;
        padding: 20px;
        border-radius: 0;
        border: 1px solid black;
        li {
          padding: 2px 0;
        }
      }
    }

    .menu-small-text {
      color: #000000;
    }
  }
}

.offcanvas-end {
  width: 70vw !important;
}
