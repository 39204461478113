.recommended-news {
  font-size: 15px;
  line-height: 1.25em;

  .head {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25em;
    color: #1d1d1b;
  }

  .news {
    display: block;
    padding: 8px 0;
    border-bottom: solid 1px #939598;
    color: black;
    font-weight: 300;

    &:hover,
    &:focus {
      color: #37b34a;
      opacity: 1;
    }

    .writer-source-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .source-image {
        width: 52px;
        height: 52px;
        margin: 0 8px 0 0;
        border-radius: 26px;
        flex: 0 0 auto;
      }

      .writer-source {
        flex: 1;
        padding-bottom: 4px;

        .writer {
          font-weight: 700;
        }

        .source {
          font-weight: 400;
          font-size: 14px;
          line-height: 1.25em;
          font-style: italic;
        }
      }
    }

    .title {
      font-weight: 500;
      padding: 8px 0;
    }

    .lead {
      font-size: 14px;
      line-height: 1.25em;
    }
  }
}
