@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.main-page {
  .post-tile .responsive {   
    &.limit-height {
      max-height: calc(100vh - 6.7708vw);  
      
      img {
        max-height: calc(100vh - 6.7708vw);  
      }
    }
    
    &.tablet, &.desktop {

      .gradient-mask {
        width: 100%;
        height: 100%
      }
      img {
        width: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }    
  } 
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .main-page {
    .col-sm-6 {
      .post-tile.article-container {
        padding-top: 47.25rem;
  
        .article-card {
          border-radius: 1.5rem 0 0 0;
          margin-left: 5rem;
        }
      }
    }
  }
}
/*
@media (min-width: $breakpoint-desktop) {
  .main-page {}
}
*/