@import '@csalad-2021/common/common.scss';

.cookie-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #469d90;
  font-size: 14px;
  color: #fff;
  transition: all 0.3s;
  z-index: 100;
  max-height: 0%;
  border-top: solid 1px #266d60;
  box-shadow: 0px -2px 5px 0px rgba(0,0,0,0.5);

  .cookie-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
  }

  a {
    color: #fff;
    font-weight: 500;
    text-decoration: underline;
  }

  &.visible {
    max-height: 100%;
  }

  .cookie-message {
    border-left: solid 2px #fff;
    padding: 0 1em;
    margin-bottom: 1.25rem;
    font-style: italic;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25;
  }

  .actions {
    display: flex;
  }
}

@media (min-width: $breakpoint-tablet) {
  .cookie-popup {
    .cookie-inner {
      display: flex;
      flex-direction: row;
      padding: 2rem 2rem 2rem 4rem;
    }
    .cookie-message {
      font-size: 1.45rem;
      margin-bottom: 0;
    }
  }
}