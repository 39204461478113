@import '../../../common.scss';

.category-posts {
  font-size: 16px;
  width: inherit;
  overflow: hidden;
  border-top: solid 1px #d1d2d2;

  .category {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 8px;

    &:focus,
    &:hover {
      outline: 0;
      opacity: 0.7;
      cursor: pointer;
    }

    .category-name {
      flex: 1 1 auto;
      font-weight: 500;
    }

    .menu-arrow {
      flex: 0 auto;
    }
  }

  .posts {
    transition: height 0.3s;
    padding: 0 16px;

    .post-item {
      padding: 16px 0;
      min-height: 100px;

      &.no-image {
        margin: 0;
      }

      &:last-child {
        border: none;
        margin-bottom: 0;
      }
    }
  }
}
