@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .related-posts {
    line-height: 1.25em;

    .article-container {
      padding-top: 18vw;
    }

    .article-card {
      padding-top: 1.3rem !important;

      .article-title {
        font-size: 1.5em;
        font-weight: bold;
      }

      .article-description {
        font-size: 1.3em;
      }

      .article-more {
        span {
          font-weight: 900;
        }
        font-size: .8em;
        font-weight: 500;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .related-posts {
    .article-card {
      .article-title {
        font-size: 2.5em;
        font-weight: bold;
      }

      .article-description {
        font-size: 1.4em;
        line-height: 1.3;
      }

      .article-more {
        font-size: 1.3em;
        font-weight: 500;
        span {
          font-weight: 700;
        }
      }
    }
  }
}
