.privacy-agreement-form {
  text-align: center;

  .checkbox-wrapper {
    text-align: left;
    width: 300px;
    border-top: solid 4px #37b344;
    margin: 24px auto;
    padding: 0 20px;
  }

  .checkbox {
    margin: 8px 0;
  }
}
