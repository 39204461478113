
.popup-mask {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  z-index: 1000;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .popup {
    display: block;
    flex-direction: column;
    flex: 0;
    position: relative;
    font-size: 0;
    background: transparent;
    transition: opacity 0.3s;
    opacity: 0;

    &.leaving { opacity: 0; }
    &.entering { opacity: 1; }
    &.visible { opacity: 1; }

    a { opacity: 1; }

    img {
      max-width: calc(100vw - 100px);
      max-height: calc(100vh - 60px);
      border: solid 4px #ffff;
      border-radius: 20px;
    }

    .button {
      position: absolute;
      right: 0px;
      top: 0px;
      color: black;
      opacity: 1;
      font-weight: bold;
      background: white;
      border-radius: 15px;
      margin: 0;
      padding: 4px;
      &:hover {
        opacity: 1;
      }
    }
  }
}
