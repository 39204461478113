@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

footer {
  font-family: "Roboto Slab", serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  color: #666666;
  background-color: #e5e5e5;

  .social-icons {
    width: 30px;
    height: 30px;
  }

  a {
    display: block;
    margin-bottom: 16px;
    text-decoration: none;
    color: #666666;
    font-weight: 700;
  }

  > * {
    position: relative;
    margin: #{$col-padding}px;
  }

  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 18px 6px;

    &.csbo-logo {
      .csbo-link {
        font-size: 7em;
        img {
          height: 64px;
          margin-bottom: 12px;
        }
        span {
          font-family: "Montserrat", serif;
          float: right;
          font-weight: 700;
          padding: 7px 12px;
          line-height: 1.30em;
        }
      }
    }
  }

  .csbo-info {
    border-left: 1px solid #666666;
    padding: 0 18px;
  }

  .impresszum-link {
    line-height: 30px;
    border-left: 1px solid #666666;
    padding-left: 1rem;
    margin: 0;
  }

  .footer-mobile {
    .csbo-info {
      margin-left: 80px;
    }
  }

  .footer-center {
    flex: 2;
    font-size: 10em;
    font-weight: 500;

    .csbo-link {
      display: block;
      margin-top: 8px;
    }
  }

  .footer-right {
    flex: 2 1;
    font-size: 10em;

    .copyright {
      padding-left: 5px;
    }

    a {
      margin-bottom: 0;
    }

    .tag {
      margin-top: 24px;

      span {
        background: #666666;
        color: #fff;
        padding: 4px 16px;
        border-radius: 12px;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  .copyright-text {
    text-align: right;
  }

  .version {
    position: absolute;
    bottom: -16px;
    color: rgba(0, 0, 0, 0);

    &:hover { color: rgba(0, 0, 0, 0.7); }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  footer {
    .footer-left {
      padding: 18px;
    }

    .footer-left.csbo-logo .csbo-link {
      font-size: 12em;

      img {
        height: 44px;
      }

      span {
        padding: 7px 12px;
      }
    }

    .csbo-info {
      padding-left: 25px;
    }

    .footer-center {
      font-size: 16em;
    }

    .footer-right {
      padding-right: 32px;
      font-size: 16em;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  footer {
    padding: 3.5rem;

    .footer-left {
      padding: 18px;
    }

    .footer-left.csbo-logo .csbo-link {
      font-size: 12em;

      img {
        height: 44px;
      }

      span {
        padding: 7px 12px;
      }
    }

    .footer-center {
      font-size: 18em;
    }

    .footer-right {
      font-size: 18em;
    }
  }
}
