@import '../../../common.scss';

.tag-cloud {
  font-size: 13px;
  font-weight: 700;
  margin-top: 32px;
  color: #37b24a;
  text-align: center;

  .head {
    text-transform: uppercase;
  }

  .item {
    display: inline-block;
    color: #37b24a;
    margin: 4px 8px;
  }
}
