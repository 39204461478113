.survey {
  button { margin-top: 16px; }

  .error {
    color: #c22;
    font-weight: 700;
    margin: 16px 20px;
    font-size: 18px;
  }
}
