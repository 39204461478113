@import "@csalad-2021/common/common.scss";
@import "./common.scss";
@import "./fonts.scss";

html {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 3.408vw;
}

@media (pointer: fine) {
  #root {
    .app {
      *:focus {
        position: relative;
      }
      *:focus::before {
        content: "";
        background-color: hsla(0, 0%, 25%, 0.3);
        border-radius: 10px;
        border: 1px solid #333;
        position: absolute;
        left: -5%;
        right: -5%;
        top: -5%;
        bottom: -5%;
        width: 110%;
        height: 110%;
        z-index: 3;
        opacity: 1;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  html {
    font-size: calc(min(0.8vw, 15.36px));
  }
}

@media (min-width: $breakpoint-desktop) {
  html {
    font-size: calc(min(0.8vw, 15.36px));
  }
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  margin: auto;
  background: $app-background-color;
}

a {
  text-decoration: none;
  color: $link-color;
  transition: opacity 0.2s;

  &:focus {
    opacity: 0.9;
    outline: none;
  }
}

.offcanvas {
  overflow: auto;
  font-size: $base-font-size-mobile;

  .offcanvas-header {
    font-size: 20em;
  }
}

@media (min-width: $breakpoint-tablet) {
  .app {
    font-size: calc(min($base-font-size, 0.95px));
    margin-top: 6.7708vw;
  }

  .offcanvas {
    font-size: calc(min($base-font-size, 0.95px));
  }
}

@import "~bootstrap/scss/bootstrap";
