.category-link {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.25em;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;

  .material-icons {
    font-size: 17px;
    line-height: 1.25em;
    vertical-align: bottom;
    padding-left: 4px;
  }
}
