@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.tag-page{
  display: block;

  .tag-title {
    padding: 2.25rem 0;
    margin: 0;
    text-align: center;
    font-family: 'Bodoni Moda', serif;
    font-size: 26em;
    font-weight: 700;
    text-transform: uppercase;
    color: #1fa090;

    &.hide-title {
      display: none;
    }
  }

  .posts {
    padding: 0 1.5rem;

    .post-tile {
      padding-bottom: 1.5rem;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .tag-page{
    .tag-title {
      padding: 3.5rem 0;
      font-size: 65em;
    }
    .post-tile {
      padding-bottom: 2.55rem;
      margin: 0 0.75rem;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .tag-page{
    .tag-title {
      padding: 3.5rem 0;
      font-size: 65em;
    }
    .post-tile {
      padding-bottom: 2.55rem;
      margin: 0 0.75rem;
    }
  }
}
