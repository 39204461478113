@use "sass:math";
@import "./common.scss";

.post-content {
	.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
		position: absolute !important;
	}

	iframe {
		max-width: 100% !important;
		// height: 40vh !important;
	}

	.ck-content {
		font-size: 13em;
		font-weight: 400;
		background-color: white;
		font-family: "Roboto Slab", serif;
		tab-size: 4;
		text-align: left;
		white-space: pre-line;
		word-wrap: break-word;
		color: $ckColorText;

		a {
			color: $link-color;
			font-weight: 700;
			text-decoration: underline;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			text-transform: uppercase;
			color: $ckHeadingColor;
		}

		p,
		pre,
		blockquote,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			padding: 0 0 0.25em;
			margin: 1em 0 0.25em 0;
			line-height: 1.3em;
		}

		ol,
		ul {
			list-style-position: outside;
			margin: 0.25em 0 0.35em 0;
			padding: 0 0 0 2em;
			line-height: normal;

			li {
				padding: 0.15em 0;
			}
		}

		ul {
			list-style-type: disc;
		}
		ul ul {
			list-style-type: circle;
		}
		ul ul ul {
			list-style-type: square;
		}

		img {
			width: 100%;
			object-fit: contain;
			object-position: center;

			.align-left {
				float: left;
			}
			.align-right,
			.alignright {
				float: right;
			}
		}

		section {
			float: left;
			width: 33%;
			border: solid 1px #37b34a;
			border-radius: 20px;
			padding: 20px;
			margin: 20px 20px 20px 0;
			color: #37b34a;
			font-weight: 700;
			box-sizing: border-box;
		}

		blockquote {
			overflow: hidden;
			border-left: none;
			border-top: 3px solid #37b34a;
			margin: 16px 12px 0 0;
			width: 33%;
			padding: 8px 0;
			color: #37b34a;
			font-weight: 700;
			line-height: 1.3em;
			float: left;
			font-style: italic;
			padding-right: 3px;
		}
		blockquote p {
			font-size: 1em;
		}

		.text-tiny {
			font-size: 0.7em;
		}
		.text-small {
			font-size: 0.85em;
		}
		.text-big {
			font-size: 1.4em;
		}
		.text-huge {
			font-size: 1.8em;
		}
		hr {
			margin: 15px 0;
			height: 4px;
			background: #dedede;
			border: 0;
		}
		.image {
			display: table;
			clear: both;
			text-align: center;
			margin: 0.9em auto;
			min-width: 50px;
			img {
				display: block;
				margin: 0 auto;
				max-width: 100%;
				min-width: 100%;
			}
			> figcaption {
				display: table-caption;
				caption-side: bottom;
				word-break: break-word;
				color: $ckColorImageCaptionText;
				background-color: $ckColorImageCaptionBackground;
				padding: 0.6em;
				outline-offset: -1px;
			}
		}
		.image-inline {
			display: inline-flex;
			max-width: 100%;
			align-items: flex-start;

			&.image-style-align-left,
			&.image-style-align-right {
				margin-top: $ckInlineImageStyleSpacing;
				margin-bottom: $ckInlineImageStyleSpacing;
			}
			&.image-style-align-left {
				margin-right: $ckInlineImageStyleSpacing;
			}
			&.image-style-align-right {
				margin-left: $ckInlineImageStyleSpacing;
			}

			img,
			picture {
				flex-grow: 1;
				flex-shrink: 1;
				max-width: 100%;
			}

			picture {
				display: flex;
			}
		}
		.image.image_resized {
			max-width: 100%;
			display: block;
			box-sizing: border-box;
			img {
				width: 100%;
			}
			> figcaption {
				display: block;
			}
		}
		.image-style-block-align-left {
			max-width: calc(100% - $ckImageStyleSpacing);
			margin-left: 0;
			margin-right: auto;
		}
		.image-style-block-align-right {
			max-width: calc(100% - $ckImageStyleSpacing);
			margin-right: 0;
			margin-left: auto;
		}
		.image-style-align-left {
			clear: none;
			float: left;
			margin-right: $ckImageStyleSpacing;
		}
		.image-style-align-right {
			clear: none;
			float: right;
			margin-left: $ckImageStyleSpacing;
		}
		.image-style-side {
			float: right;
			margin-left: $ckImageStyleSpacing;
			max-width: 50%;
		}
		.image-style-align-center {
			margin-left: auto;
			margin-right: auto;
		}
		p + .image-style-align-left,
		p + .image-style-align-right,
		p + .image-style-side {
			margin-top: 0;
		}

		.media {
			clear: both;
			margin: 0.9em 0;
			display: block;
			min-width: 15em;
		}

		.table {
			margin: 0.9em auto;
			display: table;
			table {
				border-collapse: collapse;
				border-spacing: 0;
				width: 100%;
				height: 100%;
				border: 1px double #b3b3b3;
				td,
				th {
					min-width: 2em;
					padding: 0.4em;
					border: 1px solid #bfbfbf;
				}
				th {
					font-weight: 700;
					background: hsla(0, 0%, 0%, 5%);
				}
			}
			> figcaption {
				display: table-caption;
				caption-side: top;
				word-break: break-word;
				text-align: center;
				color: $ckColorTableCaptionText;
				background-color: $ckColorTableCaptionBackground;
				padding: 0.6em;
				outline-offset: -1px;
			}
		}
		figure.table {
			display: block;
			overflow: scroll;
		}
	}

	.ck-content[dir="rtl"] {
		blockquote {
			border-left: 0;
			border-right: 5px solid #ccc;
		}
		.table {
			th {
				text-align: right;
			}
		}
	}
}

@mixin mobile-config {
	.ck-content {
		font-size: 14em;
	}
	p {
		font-size: 1em;
	}
	blockquote {
		font-size: 1em;
		&,
		& p {
			font-weight: 700;
		}
	}
	h1,
	h2 {
		font-size: 1em;
		font-weight: 700;
	}
	h3 {
		font-size: 1em;
		font-weight: 700;
	}
	h4 {
		font-size: 1em;
		font-weight: 700;
	}
	.image > figcaption {
		font-size: 1em;
	}
}

@mixin tablet-config {
	p {
		font-size: 1.5385em;
		font-weight: 500;
	}
	blockquote {
		font-size: 1.5385em;
		font-weight: 700;
		&,
		& p {
			font-weight: 700;
		}
	}
	h1,
	h2 {
		font-size: 1.5385em;
		font-weight: 700;
	}
	ol,
	ul {
		li {
			font-size: 1.5385em;
		}
	}
	h3 {
		font-size: 1.5385em;
		font-weight: 700;
	}
	h4 {
		font-size: 1.5385em;
		font-weight: 700;
	}
	.image > figcaption {
		font-size: 1.5385em;
	}
}

@mixin desktop-config {
	p {
		font-size: 1.6154em;
		font-weight: 500;
	}
	blockquote {
		font-size: 1.6154em;
		&,
		& p {
			font-weight: 700;
		}
	}
	h1,
	h2 {
		font-size: 2em;
		font-weight: 700;
	}
	ol,
	ul {
		li {
			font-size: 1.6154em;
		}
	}
	h3 {
		font-size: 1.6154em;
		font-weight: 700;
	}
	h4 {
		font-size: 1.6154em;
		font-weight: 700;
	}
	.image > figcaption {
		font-size: 1.6154em;
	}
}

.post-content {
	@include mobile-config;

	&.mobile {
		.ck-editor {
			width: 720px;
			letter-spacing: normal;
			background: #eee;

			.ck-content {
				font-size: 22.1px;
				padding: 0px 109.4px;
				border: solid 1px var(--ck-color-base-border);
				width: 720px;
			}
		}
	}

	&.tablet {
		@include tablet-config;
	}

	&.desktop {
		@include desktop-config;
		.ck-content {
			font-size: 0.62vw;
		}
	}
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
	.post-content.content {
		@include tablet-config;
	}
}

@media (min-width: $breakpoint-desktop) {
	.post-content.content {
		@include desktop-config;
	}
}
