@import "../../../common.scss";
@import "@csalad-2021/common/common.scss";

nav.header {
  border-bottom: 4px solid $header-border-color;
  background-color: white;
  padding: 0;
  margin: 0;
  height: 130.5px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;

  &.navbar > .container-fluid {
    align-items: flex-end;
    max-width: 1920px;
    height: 100%;
  }

  .csalad-logo {
    font-family: "Montserrat", serif;
    font-weight: bolder;
    color: #00a090 !important;
    text-transform: uppercase;
    vertical-align: bottom;
    position: relative;
    margin: 0;
    padding: 0;

    img {
      height: 2.9em;
      margin: 0.6em;
    }
    span {
      position: absolute;
      bottom: 0.3em;
      font-size: 1.6em;
    }
  }

  .navbar.navbar-light {
    position: fixed;
    width: 100%;
    z-index: 4;
    top: 0;
    padding: 0;
  }
  .hamburger-menu {
    margin: 0 0 18px 0px;
    padding-right: 0 !important;
    border: none;
    box-shadow: none;
    .hamburger-icon {
      width: 1.3465em;
      height: 1.295em;
      fill: $header-border-color;
    }
  }

  .search-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0 2.5rem 0 4.25rem;

    .search-close {
      background-color: white;
      color: black;
      border: none;
      font-size: 20em;

      svg {
        width: 3rem;
        height: 3rem;
        stroke: black;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: white;
        color: black;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    .search-center {
      flex: 1 0;
    }

    .search-form {
      flex: 0;
    }
  }
}

nav.header.sz2020 {
  height: 42px;

  .csalad-logo {
    position: static;
    img {
      position: absolute;
      height: 25px;
      margin: 0;
      left: 15px;
      top: 8px;
    }
    span {
      top: 18px;
      left: 48px;
      font-size: 11px;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  nav.header {
    height: auto;
  }
  .mob-nav-en {
    margin: 0 5px 12px 0px;
    display: block !important;
    padding: 0.5rem 0 !important;
    img {
      width: 32px;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  nav.header {
    height: 6.7708vw;

    .dropdown-menu {
      font-size: inherit !important;
      width: 270px;
    }

    .csalad-logo {
      img {
        height: 4.79167vw;
        margin: 0.625vw;
        margin-left: 1.05vw;
      }
      span {
        bottom: 0.35em;
        font-size: 1.875vw;
      }
    }
  }

  nav.header.sz2020 {
    height: 58px;

    .csalad-logo {
      position: relative;
      img {
        position: static;
        height: 30px;
        margin: 10px;
        margin-left: 10px;
      }
      span {
        position: absolute;
        bottom: 5px;
        font-size: 16px;
      }
    }
  }
}
