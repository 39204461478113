$size: 1.25;

.checkbox {
  display: flex;
  align-items: center;
  .check-wrap {
    font-size: 0;
  }

  input {
    appearance: none;
    background-image: url(/resources/images/checkbox.png);
    background-position: #{$size}rem;
    margin: 0 0.8rem 0 0;
    width: #{$size}rem;
    height: #{$size}rem;
    background-size: #{$size * 2}rem;
    &:checked {
      background-position: 0rem;
    }
  }
}