@import '@csalad-2021/common/common.scss';
@import '../../../common.scss';

.category-page {
  display: block;

  .category-logo {
    height: 6.55rem;
    width: 100%;
    padding-bottom: 2.25rem;
    margin: 0;
    background: transparent;
    object-fit: cover;

    &.full-size {
      height: auto;
    }
  }

  .category-title {
    padding-bottom: 2.25rem;
    margin: 0;
    text-align: center;
    font-family: 'Bodoni Moda', serif;
    font-size: 26em;
    font-weight: 700;
    text-transform: uppercase;
    color: #1fa090;

    &.hide-title {
      display: none;
    }

    &:first-child {
      padding-top: 2.25rem;
    }
  }

  .posts {
    padding: 0 1.5rem;

    .post-tile {
      padding-bottom: 1.5rem;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .category-page {
    .category-logo {
      padding-bottom: 3.5rem;
      height: 15.25rem;
    }
    
    .category-title {
      padding-bottom: 3.5rem;
      font-size: 65em;

      &:first-child {
        padding-top: 3.5rem;
      }
    }
    .post-tile {
      padding-bottom: 2.55rem;
      margin: 0 0.75rem;
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .category-page {
    .category-logo {
      padding-bottom: 3.5rem;
      height: 15.25rem;
    }

    .category-title {
      padding-bottom: 3.5rem;
      font-size: 65em;

      &:first-child {
        padding-top: 3.5rem;
      }
    }
    .post-tile {
      padding-bottom: 2.55rem;
      margin: 0 0.75rem;
    }
  }
}
