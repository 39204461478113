@import "@csalad-2021/common/common.scss";
@import "../../../common.scss";

.post-tile {
  display: block;
  text-decoration: none;
  position: relative;
  z-index: 0;
  // handle enter char as "pre" element split lead and title into multiple line
  white-space: pre-line;
  a {
    color: $link-color;
    text-decoration: underline;
  }

  .responsive {
    position: relative;
    display: none;
    z-index: 0;
  }

  .gradient-mask {
    position: absolute;
    width: 100%;
    aspect-ratio: var(--aspect-ratio);
    background: radial-gradient(transparent, #0000007a);
  }
  .post-image {
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    left: 0;
    right: 0;
    margin: auto;

    .post-image-inner {
      position: relative;
    }
    img {
      width: 100%;
    }

    .hover-mask {
      position: absolute;
      display: block;
      top: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(#fff, #c0c0c0);
      mix-blend-mode: multiply;
    }
  }

  &:hover .post-image .hover-mask {
    display: none;
  }

  .lead-article-more,
  .article-more {
    .line {
      display: inline-block;
      width: 3.125rem;
      margin-right: 1.125rem;
      margin-bottom: calc(0.25em);
    }
  }

  .lead-article-more .line {
    border-bottom: solid 0.255rem #fff;
  }

  .article-more .line {
    border-bottom: solid 0.255rem #878787;
  }

  &.article-container {
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-top: 23.45rem;

    .article-card {
      width: calc(100% - 2.2rem + 2px);
      background-color: $app-background-color;
      border-radius: 1rem 0 0 0;
      margin-left: 2.2rem;
      padding: 2.3rem 0 2.3rem 2.3rem;
      line-height: 1.325em;

      .article-title {
        display: block;
        font-family: "Bodoni Moda", serif;
        font-size: 26em;
        font-weight: 400; // regular
        line-height: 1.2em;
        text-decoration: none;
        color: black;
      }

      .article-description {
        font-family: "Roboto Slab", serif;
        font-size: 13em;
        font-weight: 400; // regular
        line-height: 1.375em;
        padding-top: 2.4rem;
        color: #878787;
      }

      .article-more {
        display: block;
        font-family: "Montserrat", serif;
        font-size: 13em;
        font-weight: 700; // bold
        line-height: 1em;
        color: #767676;
        text-decoration: none;
        margin: 2.6rem 0 0 0;
      }
    }
  }

  &.lead-article-container {
    background-color: #00a090;
    color: white;

    img {
      width: 100%;
      object-fit: cover;
    }

    .lead-article-title {
      display: block;
      font-family: "Montserrat", serif;
      line-height: 1.27em;
      text-decoration: none;
      color: white;
      text-transform: uppercase;
      font-size: 25em;
      font-weight: 800; // extra bold
      padding: 3.25rem 1.725rem 0 1.725rem;
    }

    .lead-article-description {
      display: block;
      font-family: "Roboto Slab", serif;
      line-height: 1.3em;
      font-size: 17em;
      font-weight: 900; // black
      padding: 1.725rem 1.725rem 0 1.725rem;

      a {
        color: $link-color;
        text-decoration: underline;
      }
    }

    .lead-article-more {
      display: block;
      font-family: "Montserrat", serif;
      color: white;
      text-decoration: none;
      font-size: 11em;
      font-weight: 700; // bold
      line-height: 1em;
      padding: 1.725rem 1.725rem 3.25rem 1.725rem;
    }
  }

  .tablet {
    .lead-article-title {
      font-size: 44em;
      font-weight: 500;
    }
    .lead-article-description {
      font-size: 24em;
      font-weight: 500;
    }
    .lead-article-more {
      font-size: 14em;
      font-weight: 500;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .post-tile {
    .responsive.mobile {
      display: block;
    }
  }
}

@media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop) {
  .post-tile {
    .responsive.desktop {
      display: block;
    }

    &.article-container {
      padding-top: 22.5rem;

      .article-card {
        .article-title {
          font-size: 40em;
        }

        .article-description {
          color: #878787;
          font-size: 21em;
          line-height: 1.3em;
          font-weight: 400;
        }

        .article-more {
          color: #767676;
          font-weight: 500;
          font-size: 20em;
        }
      }
    }

    &.lead-article-container {
      background-color: #00a090;
      color: white;

      .title-holder {
        left: 3.5rem;
        position: absolute;
        width: 60rem;
        top: 3.5rem;
      }

      .lead-article-title {
        font-size: 67em;
        font-weight: 800;
        padding: 0;
      }

      .lead-article-description {
        font-size: 36em;
        font-weight: 700;
        left: 3.5rem;
        position: absolute;
        bottom: 3.5rem;
        width: 60rem;
        padding: 0;
      }

      .lead-article-more {
        left: 0;
        position: absolute;
        bottom: -3.5em;
        padding: 0;
        font-size: 20em;
        font-weight: 500;
      }
    }
  }
}

@media (min-width: $breakpoint-desktop) {
  .post-tile {
    .responsive.desktop {
      display: block;
    }

    &.article-container {
      padding-top: 22.5rem;

      .article-card {
        .article-title {
          font-size: 40em;
        }

        .article-description {
          color: #878787;
          font-size: 21em;
          line-height: 1.3em;
          font-weight: 400;
        }

        .article-more {
          color: #767676;
          font-weight: 500;
          font-size: 20em;
        }
      }
    }

    &.lead-article-container {
      background-color: #00a090;
      color: white;

      .title-holder {
        left: 3.5rem;
        position: absolute;
        width: 60rem;
        top: 3.5rem;
      }

      .lead-article-title {
        font-size: 67em;
        font-weight: 800;
        padding: 0;
      }

      .lead-article-description {
        font-size: 36em;
        font-weight: 700;
        left: 3.5rem;
        position: absolute;
        bottom: 3.5rem;
        width: 60rem;
        padding: 0;
      }

      .lead-article-more {
        left: 0;
        position: absolute;
        bottom: -3.5em;
        padding: 0;
        font-size: 20em;
        font-weight: 500;
      }
    }
  }
}
