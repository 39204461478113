@use "sass:math";

$app-background-color: #f0f0f0;
$link-color: #878787;
$header-border-color: #d46159;
$primary-color: #00a090;
$separator-color: #d1d3d4;

$breakpoint-tablet: 576px;
$breakpoint-desktop: 1400px;
$base-font-size: 0.05vw;
$base-font-size-mobile: 0.275vw;

$ckColorText: #878787;
$ckColorImageCaptionText: #878787;
$ckColorImageCaptionBackground: #f7f7f7;
$ckImageStyleSpacing: 1.5em;
$ckInlineImageStyleSpacing: math.div($ckImageStyleSpacing, 2);
$ckColorTableCaptionBackground: #f7f7f7;
$ckColorTableCaptionText: #333; 
$ckHeadingColor: $primary-color;