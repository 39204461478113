.vote-posts {
  .filter {
    display: flex;

    input {
      flex: 1 1 100%;
      font-size: 14px;
      height: 24px;
      padding: 4px 16px;
      border: solid 1px #d0d0d0;
      border-radius: 20px;
      background: #fff;

      &:focus {
        outline: none;
        background: #fff;
      }

      &::placeholder { color: #b0b0c0; }
    }
  }

  .empty-result {
    font-weight: bold;
    color: red;
    text-align: center;
    margin-top: 8px;
  }

  .vote-post-item {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px 0;
    border-bottom: solid 2px #d1d3d4;

    &:last-child { border-bottom: none; }

    .vote-post {
      flex: 1;

      .vote-post-title {
        display: block;
        color: #000;
        font-size: 16px;
        font-weight: 700;
        margin: 8px 0;
      }

      .vote-post-lead {
        font-weight: 500;
      }
    }

    .vote-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 0 0 200px;
      margin-left: 16px;
      padding: 0 16px;

      .vote-count {
        color: #231f20;
        text-align: center;
        padding: 16px;
        font-weight: 700;
      }

      .vote-post-button {
        display: block;
      }
    }
  }

  .vote-message {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .vote-message-dialog {
      margin: auto;
      padding: 24px 32px;
      border-radius: 20px;
      text-align: center;
      background: white;

      .msg-icon {
        font-size: 96px;
        font-weight: 400;

        .red { color: red; }
        .green { color: #37b34a; }
      }

      .msg-title {
        font-size: 18px;
        font-weight: 700;
        margin: 24px;
      }

      .msg-content {
        font-size: 16px;
        font-weight: 500;
        margin: 32px;
      }

      .msg-button {
        display: inline-block;
      }
    }
  }
}
