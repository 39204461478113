@import '@csalad-2021/common/editor.scss';
@import '../../../common.scss';

.post-page .post .ql-editor {
  background-color: $app-background-color;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;

  > * { cursor: text; }

  .ql-video {
    display: block;
    max-width: 100%;
  }
  .ql-video.ql-align-center { margin: 0 auto; }
  .ql-video.ql-align-right { margin: 0 0 0 auto; }
  .ql-bg-black { background-color: #000; }
  .ql-bg-red { background-color: #e60000; }
  .ql-bg-orange { background-color: #f90; }
  .ql-bg-yellow { background-color: #ff0; }
  .ql-bg-green { background-color: #008a00; }
  .ql-bg-blue { background-color: #06c; }
  .ql-bg-purple { background-color: #93f; }
  .ql-color-white { color: #fff; }
  .ql-color-red { color: #e60000; }
  .ql-color-orange { color: #f90; }
  .ql-color-yellow { color: #ff0; }
  .ql-color-green { color: #008a00; }
  .ql-color-blue { color: #06c; }
  .ql-color-purple { color: #93f; }
  .ql-font-serif { font-family: Georgia, Times New Roman, serif; }
  .ql-font-monospace { font-family: Monaco, Courier New, monospace; }
  .ql-size-small { font-size: 0.75em; }
  .ql-size-large { font-size: 1.5em; }
  .ql-size-huge { font-size: 2.5em; }

  .ql-direction-rtl {
    direction: rtl;
    text-align: inherit;
  }
  .ql-align-center { text-align: center; }
  .ql-align-justify { text-align: justify; }
  .ql-align-right { text-align: right; }

  .ql-editor.ql-blank::before {
    color: rgba(0, 0, 0, 0.6);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px;
  }

  &.content {

    section {
      float: left;
      width: 33%;
      border: solid 1px #37b34a;
      border-radius: 20px;
      padding: 20px;
      margin: 20px 20px 20px 0;
      color: #37b34a;
      font-weight: 700;
      box-sizing: border-box;
    }
  }
}
